import { render, staticRenderFns } from "./IndicationsTable.vue?vue&type=template&id=10c27a59&scoped=true&"
import script from "./IndicationsTable.vue?vue&type=script&lang=js&"
export * from "./IndicationsTable.vue?vue&type=script&lang=js&"
import style0 from "./IndicationsTable.vue?vue&type=style&index=0&id=10c27a59&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c27a59",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VBtn,VDataTable,VIcon,VSpacer,VSwitch,VToolbar})
