<template>
  <v-form v-if="data" :readonly="readonly" ref="form">
    <v-card>
      <v-tabs v-model="activeTab">
        <v-tab
          v-for="(item, tabIndex) in data.gvs"
          :key="tabIndex"
          :title="item.gvsFio || '-пусто-'"
          class="font-weight-bold"
          >{{ item.gvsFio || '-пусто-' }}
        </v-tab>
        <v-tab :disabled="readonly" class="font-weight-regular">Добавить</v-tab>

        <v-tab-item
          v-for="(item, itemIndex) in data.gvs"
          :key="itemIndex"
          class="pa-2"
        >
          <v-card-text>
            <v-container fluid>
              <GvsEditor
                :fio.sync="item.gvsFio"
                :spec.sync="item.gvsSpec"
                :readonly="readonly"
                @change="
                  (fio, spec, force) => {
                    doChangeGvs(fio, spec, force, itemIndex)
                  }
                "
                @blur="doBlur()"
              />
              <v-row dense>
                <v-col>
                  <HelpCard
                    border
                    outlined
                    caption="«СПРАВОЧНАЯ ИНФОРМАЦИЯ – ОЦЕНКА ЦЕЛЕСООБРАЗНОСТИ ВКЛЮЧЕНИЯ ЛП В ПЕРЕЧНИ»"
                    class="my-2"
                  >
                    <NewFeasibility />
                  </HelpCard>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-alert v-if="needRefresh" type="warning" dense text>
                    Внимание! Информация по критериям для данной экспертизы
                    изменилась, рекомендуется обновить данные
                    <template #append>
                      <v-btn
                        @click="refreshEO"
                        :disabled="!needRefresh"
                        text
                        color="green"
                        small
                        title="Обновить все данные по критериям"
                        ><v-icon>mdi-sync</v-icon></v-btn
                      >
                    </template>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    label="Наличие или целесообразность включения ЛП в КР или стандарты оказания медицинской помощи"
                    v-model.number="item.inclusionStandart"
                    :items="[0, 3]"
                    :outlined="!readonly"
                    :append-icon="editIcon"
                    type="number"
                    auto-grow
                    rows="3"
                    @input="doChange(true)"
                    @blur="doBlur()"
                  />
                </v-col>
              </v-row>
              <v-row v-if="editMode && fgbuError(item)">
                <v-col> </v-col>
                <v-col>
                  <check-alert
                    label="Баллы ФГБУ (доп. Данные)"
                    v-model="item.scoreFGBU"
                    :check-value="initScoreFGBU"
                    :hide-check="!editMode"
                    @change="refreshGfbu"
                  />
                </v-col>
                <v-col></v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <compare-scores
                    :value="data"
                    :item="item"
                    :readonly="readonly"
                    :edit-mode="editMode"
                    @change="
                      (data, force) => doChangeOwnScores(data, itemIndex, force)
                    "
                    @blur="doBlur"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-radio-group
                    class="mt-0 mb-4 pa-0"
                    row
                    v-model="item.conclusionExpediency"
                    @change="doChange(true)"
                  >
                    <v-radio
                      color="green"
                      :value="true"
                      label="ГВС считает ЦЕЛЕСООБРАЗНЫМ включение ЛП"
                    />
                    <v-radio
                      color="red"
                      :value="false"
                      label="ГВС считает НЕЦЕЛЕСООБРАЗНЫМ включение ЛП"
                    />
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    label="Комментарии"
                    v-model="item.comment"
                    :outlined="!readonly"
                    @input="doChange()"
                    @blur="doBlur()"
                    hide-details
                    rows="3"
                    auto-grow
                    class="mb-1"
                    :append-icon="editIcon"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <HelpCard
                    border
                    outlined
                    caption="СПРАВОЧНАЯ ИНФОРМАЦИЯ– БАЛЛЫ ДЛЯ РЕКОМЕНДАЦИИ К ВКЛЮЧЕНИЮ ЛП В ПЕРЕЧНИ"
                    class="my-2"
                  >
                    <RecommendScore />
                  </HelpCard>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    :value="scoreKi"
                    readonly
                    label="Оценка качества КИ"
                    type="number"
                /></v-col>
                <v-col>
                  <v-text-field
                    :value="scoreEconomic"
                    readonly
                    label="Оценка экономических последствий"
                    type="number"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    :value="scoreAdd(itemIndex)"
                    readonly
                    label="Оценка доп. данных"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row class="my-2">
                <v-col>
                  <span>Орфанный статус: </span
                  ><span class="font-weight-bold">
                    {{ data.isOrphan ? 'Да' : 'Нет' }}
                  </span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="5">
                  <v-select
                    label="ЛП набрал рекомендованное количество баллов? "
                    :items="yesOrNo"
                    v-model="item.lpPoints"
                    :outlined="!readonly"
                    @input="doChange(true)"
                    @blur="doBlur()"
                    hide-details
                    class="mb-1"
                    :append-icon="editIcon"
                /></v-col>
                <v-col cols="7">
                  <v-textarea
                    label="По каким шкалам не набрал?"
                    v-model="item.notDidPoints"
                    :outlined="!readonly"
                    @input="doChange()"
                    @blur="doBlur()"
                    hide-details
                    class="mb-1"
                    rows="3"
                    auto-grow
                    :append-icon="editIcon"
                  />
                </v-col>
              </v-row>
              <v-row v-if="editMode">
                <v-col class="text-right">
                  <gvs-delete-dialog
                    v-if="editMode"
                    :name="item.gvsFio"
                    @onAction="deleteGvs(itemIndex)"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-tab-item>

        <!-- Последняя вкладка с добавлением -->
        <v-tab-item v-if="!readonly">
          <v-card-text>
            <v-text-field
              :label="`ГВС ФИО`"
              v-model="newGvsName"
              class="mr-4"
              style="max-width: 480px"
              :readonly="readonly"
              autofocus
            >
              <template #append-outer>
                <v-btn
                  color="primary"
                  small
                  :disabled="readonly"
                  @click="addGvs"
                >
                  Добавить
                </v-btn>
              </template>
            </v-text-field>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-form>
</template>

<script>
import ExpSummaryMixin from '../ExpSummaryMixin'
import { DEFAULT_GVS_FORM } from './gvsConst'
import RecommendScore from '../scales/RecommendScore'
import HelpCard from '@/components/HelpCard'
import GvsDeleteDialog from '@/components/expSummary/summaryGVS/GvsDeleteDialog'
import AssessmentFeasibility from '@/components/expSummary/scales/AssessmentFeasibility'
import GvsEditor from '@/components/expSummary/summaryGVS/GvsEditor'
import CompareScores from '@/components/expSummary/summaryGVS/scores/CompareScores'
import CheckAlert from '@/components/CheckAlert'
import { SUB_EX_ASSESSMENT } from '../../../store/const/expertise'
import { sameObject } from '@/lib/objects'
import NewFeasibility from '@/components/expSummary/scales/NewFeasibility'
import { mapActions } from 'vuex'

export default {
  name: 'SummaryGVS',
  components: {
    CheckAlert,
    CompareScores,
    GvsEditor,
    AssessmentFeasibility,
    NewFeasibility,
    GvsDeleteDialog,
    RecommendScore,
    HelpCard,
  },
  data: () => ({
    yesOrNo: ['Да', 'Нет'],
    activeTab: 0,
    newGvsName: '',
    loading: false,
    initEO: 0,
    initFEO: 0,
    error: '',
  }),
  watch: {
    gvs: {
      handler() {
        this.reInit()
      },
    },
  },
  mixins: [ExpSummaryMixin],
  async mounted() {
    this.loading = true
    try {
      const { expertise } = await this.GET_PLP_REPORT([SUB_EX_ASSESSMENT])
      this.initEO = expertise?.assessment?.scoreEO || 0
      this.initFEO = expertise?.assessment?.maxValue || 0
    } catch (err) {
      this.error = `${err.message} ${err.error}`.trimEnd()
      throw err
    } finally {
      this.loading = false
    }
    this.reInit()
  },
  computed: {
    gvs() {
      return this.data?.gvs || {}
    },
    initScoreFGBU() {
      return this.initData?.other?.sum || 0
    },
    lastGvs() {
      return this.data.gvs?.length - 1 || 0
    },
    scoreKi() {
      return this.data?.assessmentOrganization?.qualityAssessment?.scoreEO || 0
    },
    scoreEconomic() {
      return this.data?.assessmentOrganization?.economicAssessment?.scoreEO || 0
    },
    needRefresh() {
      return (
        this.data?.gvs[0]?.initEO !== this.initEO ||
        this.data?.gvs[0]?.initFEO !== this.initFEO
      )
    },
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
    refreshEO() {
      this.data.gvs.forEach(item => {
        item.initEO = this.initEO
        item.initFEO = this.initFEO
      })
      this.doChange(true)
    },
    addGvs() {
      const newGvs = DEFAULT_GVS_FORM()
      newGvs.gvsFio = this.newGvsName
      newGvs.scoreFGBU = this.initScoreFGBU
      this.data.gvs.push(newGvs)
      this.newGvsName = ''
      this.activeTab = this.lastGvs + 1
      this.doChange(true)
    },
    deleteGvs(itemIndex) {
      this.data?.gvs?.splice(itemIndex, 1)
      this.activeTab = itemIndex - 1 || 0
      this.doChange(true)
    },
    refreshGfbu() {
      this.data.gvs.forEach(item => (item.scoreFGBU = this.initScoreFGBU))
      this.doChange(true)
    },
    fgbuError(item) {
      return item.scoreFGBU !== this.initScoreFGBU && !this.logView
    },
    doChangeGvs(fio, spec, force, itemIndex) {
      this.data.gvs[itemIndex].gvsFio = fio
      this.data.gvs[itemIndex].gvsSpec = spec
      this.doChange(force)
    },
    doChangeOwnScores(data, idx, force) {
      this.data.gvs[idx].ownScores = data
      this.doChange(force)
    },
    getScoreFGBU(itemIndex) {
      return this.data?.gvs[itemIndex].scoreFGBU || 0
    },
    scoreAdd(itemIndex) {
      return (
        this.getScoreFGBU(itemIndex) +
        this.data?.gvs[itemIndex].inclusionStandart
      )
    },
  },
}
</script>
