<template>
  <v-btn @click="loadManual" :loading="loading">
    <v-icon class="mr-2" color="green darken-4">mdi-microsoft-excel</v-icon>
    Выгрузить справочник
  </v-btn>
</template>

<script>
//компонент для выгрузки справочников КЭИ и АВБ
import Excel from '@/lib/excel'
import { mapMutations } from 'vuex'

export default {
  name: 'ExportCr',
  data: () => ({
    loading: false,
    maxFilterCount: 1,
    headers: [
      {
        name: 'id',
        label: '№',
        options: {
          width: 5,
          style: {
            alignment: {
              vertical: 'top',
            },
          },
        },
      },
      {
        name: 'shortName',
        label: 'Краткое название',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'title',
        label: 'Название',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'association',
        label: 'Ассоциации',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'year',
        label: 'Год утверждения',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'npc',
        label: 'Одобрение НПС',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
      {
        name: 'onRubricator',
        label: 'Расположен на рубрикаторе',
        options: {
          width: 50,
          style: {
            alignment: { wrapText: true, vertical: 'top' },
          },
        },
      },
    ],
  }),
  props: {
    storedData: {
      type: Array,
      required: true,
    },
    hideMatched: {
      type: Boolean,
      default: false,
    },
    nameManual: {
      type: String,
      default: 'КР',
    },
  },
  methods: {
    ...mapMutations(['SET_ERROR']),

    async loadManual() {
      this.loading = true
      const changedStoreData = JSON.parse(JSON.stringify(this.storedData))
      try {
        await Excel.downloadXLSX({
          name: 'Лист 1',
          data: async () => {
            return changedStoreData
          },
          headers: this.headers,
          fileName: `${this.nameManual}_от_${this.getTimestamp()}`,
        })
      } catch (e) {
        this.SET_ERROR({
          head: this.nameManual,
          text: `Выгрузка справочника ${this.nameManual}`,
          e,
        })
      } finally {
        this.loading = false
        this.maxFilterCount = 1
      }
    },
    getTimestamp() {
      const date = new Date()
      const addZero = v => (v.toString().length === 1 ? `0${v}` : v)
      const yyyy = date.getFullYear()
      const MM = addZero(date.getMonth() + 1)
      const dd = addZero(date.getDate())
      return `${dd}_${MM}_${yyyy}`
    },
  },
}
</script>
