<template>
  <div>
    <v-dialog width="80%" persistent :value="true" v-if="!mkbTree">
      <v-card class="pa-4">
        <v-form @submit.prevent="submitHandler">
          <v-card-title>{{ actionTitle }}</v-card-title>
          <v-card-text>
            <v-textarea
              label="Показания"
              v-model="formData.indications"
              required
              class="mb-2"
              clearable
              rows="3"
              no-resize
            />
            <v-textarea
              label="Коды МКБ"
              v-model="formData.mkb10_codes_strict"
              readonly
              class="mb-4"
              no-resize
              rows="1"
              append-outer-icon="mdi-store-search-outline"
              @click:append-outer="showMkbTree"
              hint="Нажмите на кнопку поиска справа для выбора кодов МКБ из справочника"
              persistent-hint
            />
            <v-textarea
              label="Коды МКБ с расшифровкой"
              v-model="formData.mkb10_with_name"
              readonly
              class="mb-2"
              no-resize
              hint="Для выбора кодов МКБ нажмите кнопку в поле выше"
            />
            <v-alert
              v-if="error_message"
              dismissible
              type="error"
              outlined
              class="mb-0"
            >
              <span v-html="error_message" />
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn
              type="submit"
              color="primary"
              class="mr-4"
              small
              :loading="saveInProgress"
              :disabled="saveInProgress || !disableSaveBtn"
              >Сохранить</v-btn
            >
            <v-btn @click="closeDialog" class="mr-4" small>Отменить</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <MkbTreeSelectDialog
      v-if="mkbTree"
      :codes-string="formData.mkb10_codes_strict"
      @close-mkb-tree="closeMkbTree"
      @confirm-selection="confirmCodes"
    />
  </div>
</template>

<script>
import { copyObject } from '@/lib/objects'
import { mapActions } from 'vuex'
import MkbTreeSelectDialog from '@/components/dict/indications/allForms/MkbTreeSelectDialog'

let TEMPLATE_FORM_DATA = {
  indications: '',
  mkb10_codes: '',
  mkb10_codes_strict: '',
  mkb10_with_name: '',
}
export default {
  name: 'IndicationsForm',
  components: { MkbTreeSelectDialog },
  props: {
    isOpenForAdd: {
      type: Boolean,
      default: false,
    },
    indication: {
      type: Object,
      required: false,
    },
    lf: {
      type: Object,
      required: false,
    },
    medication: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      formData: TEMPLATE_FORM_DATA,
      saveInProgress: false,
      error_message: '',
      mkbTree: false, // показать диалог выбор дерева МКБ или нет
    }
  },
  computed: {
    actionTitle() {
      return this.isOpenForAdd ? 'Добавить' : 'Редактировать'
    },
    disableSaveBtn() {
      return !!this.formData.indications
    },
  },
  methods: {
    ...mapActions(['EDIT_GNVLP_DICT', 'ADD_INDICATION_TO_GNVLP_DICT']),
    submitHandler() {
      if (this.isOpenForAdd) this.add()
      else this.save()
    },
    async save() {
      this.error_message = ''
      this.saveInProgress = true
      try {
        await this.EDIT_GNVLP_DICT({
          medication: this.medication,
          lecform: this.lf,
          indication: this.formData,
        })
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
    async add() {
      if (!this.formData.indications) return
      this.error_message = ''
      this.saveInProgress = true
      try {
        this.formData.dict_gnvlp_lf_id = this.lf.id // id на уровень выше, самого препарата
        this.formData.is_matched = true
        await this.ADD_INDICATION_TO_GNVLP_DICT({
          indication: this.formData,
          medication: this.medication,
        })
        this.closeDialog()
      } catch (err) {
        this.error_message = err
      } finally {
        this.saveInProgress = false
      }
    },
    closeDialog() {
      TEMPLATE_FORM_DATA = {}
      this.$emit('close-form')
    },
    showMkbTree() {
      this.mkbTree = true
    },
    closeMkbTree() {
      this.mkbTree = false
    },
    // эмит из диалога с деревом: нам приходит строка кодов + строка кодов с расшифровками
    confirmCodes(codes, codesWithNames) {
      this.mkbTree = false // закрываем показ диалога
      this.formData.mkb10_with_name = codesWithNames
      this.formData.mkb10_codes_strict = codes
    },
  },
  created() {
    if (!this.indication) return
    this.formData = copyObject(this.indication)
    this.formData.mkb10_with_name = this.formData.mkb10_codes
  },
}
</script>
