<template>
  <v-container fluid class="px-0">
    <v-row>
      <v-col cols="8">
        <v-file-input
          accept=".xlsx"
          show-size
          hide-details
          @change="selectFile"
          label="Выберете файл справочника в формате Excel"
        />
      </v-col>
      <v-col cols="4" class="d-flex align-end justify-end">
        <v-btn
          @click="updateManual"
          color="success"
          :disabled="!file"
          :loading="updating"
          ><v-icon left>mdi-update</v-icon>Обновить справочник
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import apiCall from '@/lib/axiosApi'
import * as Exceljs from 'exceljs'

export default {
  name: 'UpdateCrManual',
  data: () => ({
    loading: true,
    file: null,
    updating: false,
  }),
  methods: {
    ...mapActions(['GET_CR_DICT', 'SET_ERROR']),
    ...mapMutations(['SET_ERROR']),

    async loadDict() {
      this.loading = true
      try {
        await this.GET_CR_DICT()
      } finally {
        this.loading = false
      }
    },
    async updateManual() {
      const workbook = new Exceljs.Workbook()
      await workbook.xlsx.load(this.file)
      const book = []
      const bookData = []
      workbook.eachSheet(sheet => {
        sheet.eachRow(row => {
          book.push(row.values)
        })
      })
      book.splice(0, 1)
      book.forEach(item => {
        bookData.push({
          number: item[1],
          shortName: item[2],
          title: item[3],
          association: item[4],
          year: String(item[6]),
          npc: item[7] || '',
          onRubricator: item[8],
        })
      })
      this.updating = true
      try {
        // отправка на сервер
        await apiCall(
          'dict/cr/import',
          {
            original_filename: this.file.name,
            data: bookData,
          },
          'POST'
        )
        this.$toast.success('Успешно сохранено')
        this.loadDict()
      } catch (e) {
        this.SET_ERROR({ head: 'Обновление справочника', e })
      } finally {
        this.file = null
        this.updating = false
      }
    },
    selectFile(file) {
      this.file = file
    },
  },
}
</script>
