<template>
  <v-card height="700" :loading="loading" flat ref="mainManual">
    <div class="text-end" v-if="isManager && !selectMode">
      <span class="tip pointer" @click="changeOpen">{{ openText }}</span>
    </div>
    <ReferenceTip v-if="isManager && !selectMode" class="mb-3 mt-1">
      Выгрузка справочника КР в формате Excel доступна внизу таблицы, кнопка
      "Выгрузить справочник"
    </ReferenceTip>
    <v-expand-transition v-if="isManager && !selectMode">
      <div v-show="isOpen">
        <ReferenceTip
          >Для обновления справочника КР необходимо выбрать файл (*.xlsx),
          нажать "Обновить справочник"<br />В результате обновления произойдет
          полная замена текущего справочника</ReferenceTip
        >

        <UpdateCrManual />
      </div>
    </v-expand-transition>
    <v-card-title>
      <v-spacer />
      <v-text-field
        :loading="loading"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Поиск по названию"
        single-line
        clearable
        hide-details
      ></v-text-field>
    </v-card-title>

    <!-- Таблица с данными -->
    <v-card-text style="height: 300px">
      <v-data-table
        v-model="selectItem"
        :loading="loading"
        dense
        :search="search"
        item-key="shortName"
        :headers="headers"
        :items="changedKr"
        :items-per-page="10"
        single-select
        :show-select="selectMode"
        :footerProps="table_footer_options"
        loading-text="Справочник загружается"
      >
        <template #[`item.title`]="{ value }">
          <span :inner-html.prop="value | highlight(search)" />
        </template>
        <template #[`item.association`]="{ value }">
          <expand-text :value="value" :words-count="1" />
        </template>
        <template #[`item.npc`]="{ value }">
          <v-icon v-if="value" :title="value" color="primary">mdi-check</v-icon>
        </template>
        <template #[`item.onRubricator`]="{ value }">
          <v-icon v-if="value" :title="value" color="primary">mdi-check</v-icon>
        </template>
        <template v-slot:footer v-if="isManager">
          <div class="d-flex align-end justify-end">
            <export-cr
              class="my-3"
              v-if="dictCr && !loading && !selectMode"
              :storedData="dictCr"
            />
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions v-if="selectMode">
      <v-btn color="blue darken-1" text @click="close"> Отмена </v-btn>
      <v-btn
        :disabled="!selectItem || !selectItem.length"
        color="blue darken-1"
        text
        @click="save"
      >
        Добавить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReferenceTip from '../../ReferenceTip'
import exportCr from '@/components/dict/cr/ExportCr.vue'
import UpdateCrManual from '@/components/dict/cr/allForms/UpdateCrManual.vue'
import ExpandText from '@/components/editors/expandText.vue'

export default {
  name: 'CrTable',
  components: {
    ExpandText,
    UpdateCrManual,
    exportCr,
    ReferenceTip,
  },
  data() {
    return {
      loading: true,
      search: '',
      addSearch: '',
      sheets: [],
      isOpen: false,
      isAddOpen: false,
      selectItem: [],
      headers: [
        {
          text: 'Краткое название',
          value: 'shortName',
          sortable: false,
          cellClass: 'font-weight-bold ',
        },
        {
          text: 'Название',
          value: 'title',
          sortable: false,
        },
        {
          text: 'Ассоциация',
          align: 'justify',
          cellClass: 'py-2',
          value: 'association',
          filterable: false,
          width: 300,
        },
        {
          text: 'Год утверждения',
          align: 'justify',
          value: 'year',
          cellClass: 'py-2',
          filterable: false,
        },
        {
          text: 'Одобрение НПС',
          value: 'npc',
          filterable: false,
        },
        {
          text: 'Расположен на рубрикаторе',
          value: 'onRubricator',
          filterable: false,
        },
      ],
      table_footer_options: {
        itemsPerPageOptions: [10, 50, 100, -1],
      },
    }
  },

  async created() {
    this.loading = true
    try {
      await this.GET_CR_DICT()
    } finally {
      this.loading = false
    }
  },

  computed: {
    ...mapGetters(['isManager', 'dictCr']),
    //options для скролла до справочника
    options() {
      return {
        duration: 500,
        offset: 70,
        easing: 'easeInOutCubic',
      }
    },
    openText() {
      return this.isOpen ? 'Скрыть' : 'Обновить справочник КР'
    },
    changedKr() {
      return (
        this.dictCr?.map((item, index) => ({ ...item, id: index + 1 })) || []
      )
    },
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(['GET_CR_DICT']),
    changeOpen() {
      this.isOpen = !this.isOpen
    },
    changeAddOpen() {
      this.isAddOpen = !this.isAddOpen
    },
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('save', this.selectItem[0])
      this.selectItem = []
      this.close()
    },
  },
}
</script>
