<template>
  <v-card flat>
    <v-data-table
      dense
      :headers="feasibilityHeaders"
      :items="FEASIBILITY"
      :group-by="['criteriaScore']"
      hide-default-footer
      :items-per-page="100"
    >
      <template #[`group.header`]="{ headers, items }">
        <td :colspan="headers.length">
          <span v-if="items" class="text-overline"
            >{{ items[0].criteriaScore }}.
          </span>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
const FEASIBILITY = [
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в стандартах медицинской помощи) с учетом рекомендуемой частоты предоставления',
    resultScore: '0,8 и выше',
    scaleScore: '+ 3',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в стандартах медицинской помощи) с учетом рекомендуемой частоты предоставления',
    resultScore: '0,6-0,8',
    scaleScore: '+ 2',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в стандартах медицинской помощи) с учетом рекомендуемой частоты предоставления',
    resultScore: '0,4-0,6',
    scaleScore: '+ 1',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в стандартах медицинской помощи) с учетом рекомендуемой частоты предоставления',
    resultScore: '0,2-0,4',
    scaleScore: '+ 0,5',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в стандартах медицинской помощи) с учетом рекомендуемой частоты предоставления',
    resultScore: 'ниже 0,2',
    scaleScore: '+ 0,2',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в клинических рекомендациях (протоколах лечения)',
    resultScore: 'Да',
    scaleScore: '+ 2',
  },
  {
    criteriaScore:
      'Целесообразность включения (наличие лекарственного препарата в клинических рекомендациях (протоколах лечения)',
    resultScore: 'Нет',
    scaleScore: '0',
  },
]
export default {
  name: 'AssessmentFeasibility',
  data: () => ({
    FEASIBILITY,
    feasibilityHeaders: [
      {
        text: 'Критерий оценки',
        value: 'criteriaScore',
        sortable: false,
      },
      {
        text: 'Результат оценки',
        value: 'resultScore',
        sortable: false,
      },
      {
        text: 'Шкала оценки (балл)',
        value: 'scaleScore',
        sortable: false,
      },
      { text: '', value: 'data-table-expand' },
    ],
  }),
}
</script>

<style scoped></style>
